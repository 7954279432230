@font-face {
    font-family: gMond;
    src: url(Deco/bulgarian_garamond_normal_font.ttf);
  }

header, footer { padding-top:1em; background-color:rgba(0, 187, 255, 0.57); text-align:center;}
h1 a {font-family:gMond; text-align:center; display:block; padding:1px; position: relative; bottom: 70px;}
h1 a:hover, h3 a:hover{ color: yellow; text-decoration: none; transition-duration: 1s;}
h1 a:not(:hover){color: black; transition-duration: 1.5s;}
#logo {height: 150px; width: 150px; position: relative; top: 60px; right: 20px; border:none}
body {position: relative;}
html {background: url("./Deco/forest-morning-fall-feb-17.jpg") no-repeat center fixed; background-size: cover; background-color:lightblue;}