#apHeading:hover {font-family: gMond; text-align: center; font-size: 22px; color: yellow; transition-duration: 1s;}
#apHeading:not(:hover) {font-family: gMond; text-align: center; font-size: 20px; text-decoration: underline; transition-duration: 1s;}
.enclosure {position: relative; display:block; margin-left: auto; margin-right: auto; width: 80%; text-align: center;}
hr {border-bottom:3px solid white; opacity: 0.5;}

.artSlide.fadeOut { position: relative; opacity: 0.57; transition-duration: 0.18s; width:18%; height:18%; border:3px solid black; margin: 4px; z-index: 1;}
.artSlide.fadeOut:hover{ border:3px solid yellow;}
.artSlideFadeIn {position: relative; opacity: 1; transition-duration: 1.4s; transform: scale(1.12); width:18%; height:18%; border:3px solid black; margin: 4px; z-index: 1;}
.artSlideFadeIn:hover{ border:3px solid yellow; filter: drop-shadow(0.25rem 0.25rem 1rem white);}
.mainSlide{color: yellow; size: 50px;}
.secondarySlide {position: relative; width:18%; height:18%; border:3px solid black; margin: 4px; opacity: 0.74; z-index: 0;}
.secondarySlide:hover{ border:3px solid yellow;}
.tertiarySlide {position: relative; width:18%; height:18%; border:3px solid black; margin: 4px; opacity: 0.22; z-index: 0;}
.tertiarySlide:hover{ border:3px solid yellow;}
.arrowDivL {position: absolute; display:inline-block; top: 55%; left: 4%; margin-top: auto; margin-bottom: auto; z-index: 1;}
.arrowDivR {position: absolute; display:inline-block; top: 55%; right: 4%; margin-top: auto; margin-bottom: auto; z-index: 1;}
.arrow:not(:hover) {width: 70%; height: 70%; filter: drop-shadow(0.25rem 0.25rem 0.15rem black); transition-duration: 0.5s;}
.arrow:hover {width: 80%; height: 80%; filter: drop-shadow(0.25rem 0.25rem 0.15rem black); transition-duration: 0.5s; }
#referral{color: white;}
#brush {width: 31px;}
#store:not(:hover) {position: relative; display: block; margin-left: auto; margin-right: auto; width: 55px; border: 2px solid black; border-radius: 50px; transition-duration: 1s; padding: 1px;}
#store:hover {position: relative; display: block; margin-left: auto; margin-right: auto; width: 65px; border: 2px solid black; border-radius: 100px; filter: drop-shadow(0.25rem 0.25rem 0.25rem white); transition-duration: 1s;}

