

footer {
    background-color:rgba(0, 187, 255, 0.57);
    width:100%;
    padding: 0px; position:fixed; 
    bottom: 0px;
}

footer p {
    display: block;
    text-align: center;
    width: 5% auto;
    margin: 0px;
    font-family: Cambria;
    font-size: 18px;
    color: black;
}

.mailImg {float: inline-start; width: 30px;}

.mailP {
    display: inline-block;
}

.mailP a:hover {
    color: yellow; transition-duration: 1s; 
    text-decoration: none;
}
.mailP a:not(:hover) {
    color: black;
    text-decoration: none; 
    transition-duration: 1s; 
}


