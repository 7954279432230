@font-face {
    font-family: gMond;
    src: url(bulgarian_garamond_normal_font.ttf);
  } 
   p {font-family:gMond; font-size:18.5px; color:white; line-height: 1.5;}
   sp {font-family: 'Courier New', Courier, monospace; font-size:18.5px;}
   h1:hover {text-align: center; font-family: gMond;color:yellow; transition-duration: 1s;}
   h1:not(:hover) {text-align: center; font-family: gMond; transition-duration: 1s;}
   .whiteLink {color: white;}
  .PSCard {margin: auto; height: 37%; width: 60%; border: 3px solid rgba(0, 0, 0, 0.521); background-color: #87cfeb50; padding: 1%}
  #title {font-family:gMond; text-align:center}