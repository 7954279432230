.banner:not(:hover) {height: 15%; width: 15%; color: white; background-color:white; border:2.7px solid black; border-radius:10%; 
  padding:5px; margin-left: auto; margin-right: auto; margin-top: 0px; margin-bottom: 40px; transition-duration: 2s; filter: drop-shadow(0.25rem 0.25rem 0.5rem black)}
#appsHeader:not(:hover) {font-family: gMond; font-size: 20px; text-align: center; text-decoration: underline; transition-duration: 1s; margin-bottom: 4px;}
#appsHeader:hover {font-family: gMond; font-size: 22px; text-align: center; color: yellow; transition-duration: 1s;  margin-bottom: 4px;}
.banner:hover{height: 16%; width: 16%; color: white; background-color:white; border-radius:10%; 
  padding:5px; margin: 0 auto; margin-bottom: 40px; border:2.7px solid yellow; transition-duration: 1s; filter: drop-shadow(0.25rem 0.25rem 0.5rem black);}

#foh {border:none; height: 100%; width: 100%;}
#appIcon {width: 31px;}

.AppsDiv {
  margin-bottom: 0px;
}
